@import './abstracts/_mixins',
'./abstracts/_variables',
'./components/button';

.header {
  &__banner {
    background-color: var(--toc-blue);
    color: var(--toc-white);

    * {
      margin: 0;
    }
  }

  &__infos {
    @include color(--toc-white, --toc-light-brown);
    grid-column: 1/3;

    &__navigation {
      font-size: 1.1rem;
      width: 100%;

      &__list {
        @include flex-row-justify(space-between);
        height: 3.5rem;
        margin: 0;

        & > li {
          position: relative;

          &.free-delivery {
            .-text-icon::after {
              @include icons('Line Awesome Free', "\f05a", 2rem, var(--toc-white));
              position: absolute;
              margin-left: 0.5rem;
              top: 50%;
              transform: translateY(-50%);
            }
          }

          &.free-store-pickup {
            .-text-icon::after {
              @include icons('Line Awesome Free', "\f05a", 2rem, var(--toc-white));
              position: absolute;
              margin-left: 0.5rem;
              top: 50%;
              transform: translateY(-50%);
            }
          }

          &.date-stores-link {
            & > a {
              color: var(--toc-white);
            }

            .-text-icon::before {
              @include icons('Line Awesome Free', "\f095", 2rem, var(--toc-white));
              width: 2rem;
              height: 2rem;
              border-radius: 2rem;
              margin-left: 0.5rem;
              margin-right: 0.1rem;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
  }

  @media (max-width: $breakpoint-xl) {
    &__banner,
    &__infos {
      display: none !important;
    }
  }
}

/* ************************** DESIGN FOR MENU : DESKTOP & MOBILE ************************** */
.section-menu {
  /* ************ Generic design for Desktop & Mobile ************ */
  position: sticky;
  top: 0;
  padding-top: 0.5rem;
  background: var(--toc-brown);
  z-index: 101;

  .grid-responsive {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .logo {
      a {
        display: inline-block;
        background: url(#{$imagesPath}/logo.svg) no-repeat;
        width: 255px;
        height: 8rem;
        background-size: 25rem;
        transition: .2s ease-in-out;
      }
    }

    .bar {
      width: 50%;

      //override parent blocksearch.css module
      #search_block_top {
        position: inherit;
        right: inherit;
        top: inherit;

        #searchbox {
          position: relative;
          padding: 0;

          #search_query_top {
            display: inline-block;
            box-shadow: none !important;
            width: 100%;
            height: 4.5rem;
            font-size: 1.4rem;

            &::placeholder {
              font-style: italic;
              font-size: 1.4rem;
            }
          }

          .btn.button-search {
            display: block;
            position: absolute;
            padding: 0;
            top: 0;
            right: 0;
            bottom: 0;
            width: 5rem;
            color: var(--toc-white);
            background: var(--toc-flash);
            border: none;
            outline: none;

            &::before {
              content: " \f002";
              font-family: $lineAwesome;
              font-weight: 900;
              display: inline-flex;
              font-size: 2.5rem;
              height: 4rem;
              transform: rotate(270deg);
            }

            &:hover {
              color: var(--toc-brown);
            }

            span {
              display: none;
            }
          }
        }

        .text-search-bar {
          color: var(--toc-orange);
          font-size: 1.5rem;
          text-align: center;
          text-transform: uppercase;
          font-weight: bold;
          display: block;
        }
      }
    }

    .navigation {
      @include flex-row-justify(flex-end);
      gap: 2rem;

      &__store {
        @include icon-toc("\f3c5");

        &-js {
          & > button {
            position: relative;
            border: none;
            background: none;
            color: var(--toc-white);

            .icon-store-check {
              &::after {
                @include icons($fontAwesomeFree, $check, 2rem, none);
                @include flex-column-justify(center);
                position: absolute;
                top: 0;
                left: 50%;
                width: 2.5rem;
                height: 2.5rem;
                background-color: var(--toc-green);
                border-radius: 50%;
                color: white
              }
            }
          }
        }
      }

      &__account {
        @include icon-toc("\f007");

        &__user {
          &__logged {
            height: 100%;

            a {
              @include flex-column-justify(space-between);
              height: 100%;

            }

            &__message {
              @include flex-row-justify(center);

              &__hello {
                text-transform: capitalize;
              }

              &__name {
                &::after {
                  content: attr(data-logged);
                }

                color: var(--toc-orange);
              }
            }
          }
        }
      }

      &__cart:not(.icon-shopping) {
        @include icon-toc("\f290");
      }

      &__cart {
        &__shopping {
          position: relative;
        }
      }
    }

    .menu {
      display: flex;
      width: 100%;
      transform: translateY(1.5rem);

      &__desktop {
        position: relative;

        .nav-item {
          position: inherit;
          padding-right: 0;
          padding-left: 1.5rem;
          justify-content: center;
        }

        &__buttons {
          position: initial;

          .nav-item {
            &:nth-child(1) {
              padding-left: 0
            }
          }

          button {
            @include button-standart(primary);
            //override mixin
            border-radius: 0 !important;
            position: relative;
            padding: 1rem 3rem 1rem 1.5rem;
            width: 100%;
            max-width: 12vw;
            font-weight: 600;
            text-shadow: none;
            box-shadow: #c8c8c875 0 2px 4px -1px;
            z-index: 2;

            &::after {
              @include icons($fontAwesomeFree, '\f0d7', 2rem, var(--toc-white));
              position: absolute;
              right: 1rem;
              top: 50%;
              transform: translateY(-50%);
            }

            span {
              display: block;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              pointer-events: none;
            }

            &.is-active {
              background: var(--toc-noug-hover) !important;

              &::before {
                content: "";
                z-index: 2;
                position: absolute;
                width: 0;
                height: 0;
                right: 1rem;
                top: 3rem;
                border: 8px solid;
                border-color: var(--toc-orange-hover) transparent transparent var(--toc-orange-hover);
                transform: rotate(225deg);
              }

              &::after {
                content: "\f0d7";
                display: inline-flex;
                position: absolute;
                top: 4rem;
                right: 1.2rem;
                font-weight: 900;
                font-style: normal;
                font-variant: normal;
                text-rendering: auto;
                line-height: 1;
                font-size: 2rem;
                color: var(--toc-white);
                text-align: center;
                z-index: 2;
              }
            }
          }

          &__navigations {
            display: none;

            &.show-menu {
              display: block;
              position: absolute;
              left: 0;
              right: 0;
              border-bottom: 3px solid var(--toc-orange);
              box-shadow: #00000059 0 2px 4px -1px;
              z-index: 1;
            }

            aside {
              @include scrollbar();
              background-color: var(--toc-grey);
            }

            main {
              box-shadow: 0 2px 5px 0px #cfcfcfbf;
              background-color: var(--toc-white);

              //override bootstrap
              .tab-content > .active {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
              }
            }

            &__tab {
              flex: 1 1 80%;
              column-count: 3;

              &__nav-links {
                .nav-link {
                  padding: 1rem;
                  font-size: 1.5rem;
                  font-weight: 600;
                  text-transform: uppercase;
                  color: var(--toc-orange);
                  display: flex;
                  align-items: center;
                  cursor: pointer;

                  &:first-child {
                    margin-top: 1rem;
                  }

                  &:last-child {
                    margin-bottom: 0.5rem;
                  }

                  svg {
                    width: 2.5rem;
                    height: 2.5rem;
                    margin-right: 1rem;
                  }

                  svg {
                    fill: var(--toc-brown);
                  }

                  &.active {
                    border-left: 4px solid var(--toc-orange);
                    background-color: var(--toc-white);
                    color: var(--toc-brown);
                    z-index: 2;

                    svg {
                      fill: var(--toc-orange);
                    }
                  }
                }
              }

              &__childs-categories {
                display: table;
                position: relative;
                padding-left: 1rem;
                margin-bottom: 5rem;
                page-break-inside: avoid;
                break-inside: avoid-column;

                &__title {
                  display: block;
                  margin-bottom: 1rem;
                  font-size: 1.5rem;
                  font-weight: 600;
                  text-transform: uppercase;
                  color: var(--toc-brown);

                  &:hover {
                    font-weight: bold;
                    color: var(--toc-orange);
                    text-decoration: underline;
                  }

                  &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    left: 0;
                    height: 100%;
                    border-left: 2px solid var(--toc-orange);
                  }
                }

                &__list {
                  display: inline-block;

                  &__items {
                    @include link();
                  }
                }
              }

              &__manufacturers {
                padding-left: 1rem;
                max-height: 65vh;
                overflow-y: auto;

                &__title {
                  display: block;
                  margin-bottom: 1rem;
                  font-size: 1.5rem;
                  font-weight: 600;
                  font-size: 1.5rem;
                  text-transform: uppercase;
                  color: var(--toc-brown);

                  &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    border-left: 2px solid var(--toc-orange);
                  }
                }

                &__list {
                  display: inline-block;

                  &__items {
                    @include link();
                    text-transform: uppercase;
                    font-size: 1.3rem;
                  }
                }
              }
            }

            &__dropdown {
              position: relative;

              //override bootstrap
              .dropdown-menu {
                transform: unset !important;
                top: 100% !important;
                right: 0;
                border-bottom: 3px solid var(--toc-orange);
                background-color: var(--toc-grey);

                .dropdown-item {
                  text-transform: uppercase;
                  font-weight: 600;
                  font-size: 1.5rem;
                  color: var(--toc-orange);

                  &:hover,
                  &:focus {
                    color: var(--toc-brown);
                    background-color: var(--toc-white);
                    text-decoration: none;
                  }
                }
              }
            }

            &__see-all {
              @include link();

              &-brands {
                background-color: var(--toc-grey);
              }
            }
          }
        }

        &__links {
          padding: 0;
          box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
          background-color: var(--toc-white);

          .nav-item {
            display: flex;
            align-items: center;
            padding: 0;
            flex: 1 1 0;
            overflow: hidden;
          }

          li {
            &:not(:first-child) {
              &::before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 0.2rem;
                height: 100%;
                background-color: var(--toc-brown);
              }
            }

            &:hover {
              background: var(--toc-grey);
              box-shadow: inset 0px -4px var(--toc-orange);
            }

            a {
              @include flex-row-justify(center);
              position: relative;
              width: -moz-available;
              width: -webkit-fill-available;
              height: 100%;
              text-align: center;
              padding: 0 1.5rem;
              margin: 0;
              font-weight: 600;
              border-radius: 0;
              text-shadow: none;
              box-shadow: none;
              border: none;
              text-transform: uppercase;
              color: var(--toc-brown);
              font-size: 1.7rem;

              span {
                line-height: normal;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                pointer-events: none;
              }
            }
          }
        }
      }

      &__mobile {
        display: none;
      }
    }
  }

  /* ************ Scroll only for Desktop ************ */
  @media (min-width: $breakpoint-xl) {
    &--scrolling {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;

      .logo {
        position: relative;
        left: 10rem;
        z-index: 1;

        a {
          display: block !important;
          width: 15rem !important;
          height: 5rem !important;
          background-size: 15rem !important;
          background-position: center !important;
        }
      }

      .bar {
        z-index: 1;

        .text-search-bar {
          display: none !important;
        }
      }

      .navigation {
        gap: 4rem;
        z-index: 1;

        &__store,
        &__account,
        &__cart {
          height: auto !important;
        }

        span:not(.off-stream-navigation) {
          display: none !important;
        }
      }

      .menu {
        display: flex;
        position: absolute;
        left: 0;
        width: 100%;
        transform: none !important;
        z-index: 0;

        .menu__desktop {
          &__buttons {
            li.nav-item:not(.scroll-burger) {
              display: none;
            }

            .scroll-burger {
              position: absolute;
              top: -2rem;

              button {
                width: auto;
                max-width: none;
                background: none;
                box-shadow: none;
                padding: 0;
                left: 5rem;
                background: transparent !important;

                &::after,
                &::before {
                  display: none;
                }

                span {
                  display: flex;
                  font-size: 0;

                  &::after {
                    @include icons($fontAwesomeFree, "\f0c9", 3rem, var(--toc-orange));
                  }
                }

                &.is-active {
                  background: inherit !important;

                  &::after {
                    display: none;
                  }

                  span {
                    font-size: 0;

                    &::after {
                      @include icons($fontAwesomeFree, "\f00d", 3rem, var(--toc-orange));
                    }
                  }
                }
              }

              .show-menu {
                top: 5rem;
                box-shadow: var(--toc-orange) 0 -3px 0px 0px;
              }
            }
          }

          &__links {
            display: none;
          }
        }
      }
    }

  }

  /* ************ Responsive design for Mobile ************ */
  @media (max-width: $breakpoint-xl) {
    padding: 0.5rem 0;

    .grid-responsive {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 0.5rem;

      .logo {
        display: flex;
        grid-row: 1;
        grid-column: 1;
        position: relative;
        left: 7rem;
        width: fit-content;
        z-index: 2;

        a {
          display: inline-block;
          background: url(#{$imagesPath}/logo-responsive.svg) no-repeat;
          width: 5rem !important;
          height: 5rem !important;
          background-size: 16rem !important;
        }
      }

      .bar {
        grid-column: 1/3;
        width: 100%;

        &__search {
          width: 100%;

          #search_block_top .text-search-bar {
            display: none;
          }
        }

        #search_query_top {
          height: 3.5rem !important;
        }

        .button-search {
          height: 3.5rem;

          &::before {
            position: relative;
            top: -0.5rem;
          }
        }
      }

      .navigation {
        grid-row-start: 1;
        grid-column-start: 2;
        gap: 1rem;
        text-align: center;
        z-index: 3;

        &__store,
        &__account,
        &__cart {
          width: auto;
          height: auto !important;

          &::before {
            font-size: 4rem;
          }

          & > a > span {
            font-size: 1.2rem;
          }
        }

        &__account {
          &__user {
            &__logged {
              &__message {
                @include flex-column();
              }
            }
          }
        }
      }

      .menu {
        grid-row: 1;
        grid-column: 1;
        width: fit-content;
        transform: none;

        //override Bootstrap
        .container {
          padding: 0;
        }

        &__desktop {
          display: none;
        }

        &__mobile {
          display: block;

          $primary-color: #fff; //special case --> can't compile with var(--toc-white)
          $primary-color-tint: lighten($primary-color, 50%);
          $link-color: $primary-color;
          $nav-width: 80vw;
          $nav-padding: 1.2rem 1.5rem;
          $nav-background-color: var(--toc-white);
          $nav-level-background-color: $primary-color-tint;

          .nav {
            &-items {
              flex: 0 0 100%;
              padding: 0 1.5rem;
              margin: 0;
            }

            &-item {
              &:not(:last-child) {
                border-bottom: solid 1px var(--toc-orange);
              }

              &.active {
                & > ul > li.nav-item {
                  height: auto;

                  & > .nav-link {
                    height: auto;
                    font-size: 1.3rem;

                    &.active {
                      font-size: 1rem;
                    }
                  }

                  & > .nav-back-link {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    font-size: 1rem;
                    color: var(--toc-brown);
                    background-color: var(--toc-grey) !important;

                    &::before {
                      @include icons($fontAwesomeFree, $chevron-left, 1rem, var(--toc-brown));
                      margin-right: 0.5em;
                    }
                  }
                }
              }
            }

            &-link {
              display: block;
              padding: $nav-padding;
              background-color: $nav-background-color;
              color: var(--toc-brown);
              font-size: 1.5rem;
              line-height: 1.5em;
              font-weight: 600;
              text-transform: uppercase;
            }

            &-expand {
              &.active {
                & > .nav-expand-content {
                  left: 0;
                  visibility: visible;
                  opacity: 1;
                }
              }

              &-content {
                visibility: hidden;
                height: 496px;
                overflow-y: auto;
                position: fixed;
                top: 10rem;
                bottom: 0;
                left: 0;
                width: 80vw;
                left: 100%;
                transition: 0.3s;
                opacity: 0;
                background-color: $nav-level-background-color;

                .nav-item {
                  height: 0;

                  .nav-link {
                    font-size: 0;
                    height: 0;
                  }

                  &.go-back {
                    border-bottom: 1px solid var(--toc-white);
                  }

                  &-see-categories {
                    &-link {
                      color: var(--toc-brown);
                      font-weight: 600;

                      b {
                        text-transform: uppercase;
                      }
                    }
                  }
                }
              }

              &-link {
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: var(--toc-orange);

                &::after {
                  @include icons($fontAwesomeFree, $chevron-right, initial, var(--toc-orange));
                  flex: 0 1 auto;
                }
              }
            }

            &__special {
              margin: 0;

              .nav-link {
                padding: 0;
                color: var(--toc-white);
                background-color: var(--toc-brown);

                span {
                  @include flex-row-justify(flex-start);
                  border-bottom: 1px solid var(--toc-orange);
                  padding: 1.5rem 3rem;
                }
              }

              &__stores {
                .nav-link {
                  border: none;
                  width: 100%;

                  span {
                    &::before {
                      @include icons($lineAwesome, '\f3c5', 2rem, var(--toc-white));
                      margin-right: 1rem;
                    }
                  }
                }
              }

              &__account {
                .nav-link {
                  span {
                    &::before {
                      @include icons($lineAwesome, '\f007', 2rem, var(--toc-white));
                      margin-right: 1rem;
                    }
                  }
                }
              }

              &__help {
                .nav-link {
                  span {
                    &::before {
                      @include icons($lineAwesome, '\f05a', 2rem, var(--toc-white));
                      margin-right: 1rem;
                    }
                  }
                }
              }
            }
          }

          &__content {
            visibility: hidden;
            position: absolute;
            margin-top: 10rem;
            margin-left: -$nav-width;
            opacity: 0;
            box-shadow: #000000 0 -5px 0 0;

            &.is-showing {
              visibility: visible;
              margin-left: 0;
              top: 0;
              left: 0;
              width: $nav-width;
              overflow-y: auto;
              overflow-x: hidden;
              opacity: 1;
              border-bottom: 3px solid var(--toc-orange);
              background-color: $nav-background-color;
              transition: 0.3s ease-in-out;
              z-index: 100;
            }
          }

          .burger-animated {
            display: block;
            width: 3rem;
            height: 2rem;
            position: relative;
            margin: 0;
            transform: rotate(0deg);
            transition: .5s ease-in-out;
            cursor: pointer;

            span {
              display: block;
              position: absolute;
              height: 0.3rem;
              width: 100%;
              border-radius: 1rem;
              opacity: 1;
              left: 0;
              transform: rotate(0deg);
              -webkit-transition: .25s ease-in-out;
              -moz-transition: .25s ease-in-out;
              -o-transition: .25s ease-in-out;
              transition: .25s ease-in-out;
              background: #e65100;

              &:nth-child(1) {
                top: 0;
              }

              &:nth-child(2) {
                top: 1rem;
              }

              &:nth-child(3) {
                top: 2rem;
              }
            }

            &.open {
              span {
                &:nth-child(1) {
                  top: 1rem;
                  transform: rotate(135deg);
                }

                &:nth-child(2) {
                  opacity: 0;
                  left: -6rem;
                }

                &:nth-child(3) {
                  top: 1rem;
                  transform: rotate(-135deg);
                }
              }
            }
          }
        }
      }
    }

    .hidden--scrolling {
      display: none;
    }
  }
  @media (max-width: $breakpoint-sm) {
    .grid-responsive {
      .logo {
        padding: 0;
      }

      .navigation {
        padding-left: 0;
        gap: 1.5rem;

        &__account {
          &__user {
            &__logged {
              &__message {
                &__name {
                  &::after {
                    content: attr(data-responsive-logged);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}